import getDefaultImageUrls from './getDefaultImageUrls';
import getSourceSet from './getSourceSet';
import { ResponsiveImageConfiguration } from './graphql/api/graphql';

const getProxiedImages = (
  variables: {
    imageConfigurations: ResponsiveImageConfiguration[];
  },
  originalImageUrls: (string | null | undefined)[],
) => {
  const { imageConfigurations } = variables;

  const defaultImageUrls = getDefaultImageUrls(imageConfigurations) as string[];

  const imageUrls = (originalImageUrls.every(
    (url) => typeof url === 'string',
  ) ? originalImageUrls : defaultImageUrls) as string[];

  const sourceSet = getSourceSet(imageConfigurations, imageUrls);

  return { imageUrls, srcSet: sourceSet };
};

export default getProxiedImages;
