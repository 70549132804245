import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FlexSmallCardContainer } from '../../../Cards/Small';
import breakpoints from '../../../Theme/breakpoints';

const OuterContainer = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.sm}px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: ${breakpoints.lg}px) {
    flex-wrap: nowrap;
  }

  min-height: 1000px;
`;

const FeaturedItems = styled.section`

@media (min-width: ${breakpoints.lg}px) {
   flex-basis: calc(74%);
}
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const SpotlightArticle = styled.div`
  padding: 0 0 24px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 0 0 16px;
    order: 2;
    flex-basis: calc(65% - 16px);
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 0;
    margin: 0 auto;
    border: none;
    flex-basis: calc(65% - 48px);
  }
`;

const LastArticle = styled.div`
  padding: 0 0 24px;
  order: 4;

  @media (min-width: ${breakpoints.sm}px) {
    padding: 16px 0;
    margin: 16px 0;
    border-bottom: 1px solid var(--grey-light);
    border-top: 1px solid var(--grey-light);
  }

  @media (min-width: ${breakpoints.lg}px) {
    flex-basis: calc(100% - 24px);
    border-bottom: none;
    margin: 24px 0 0;
    padding: 24px 0 0 0;
  }
`;

const SideBar = styled.section`
  flex: 100%;

  @media (min-width: ${breakpoints.lg}px) {
    flex: 0 0 300px;
    padding-left: 24px;
    border-left: 1px solid var(--grey-light);
  }
  @media (min-width: 1400px) {
    flex: calc(26% - (24px - 1px));
  }
`;

const SideBarArticles = styled.div`
  order: 2;

  @media (min-width: ${breakpoints.md}px) {
    order: 1;
    flex-basis: calc(35% - (16px + 1px));
    padding: 0 16px 0 0;
    border-right: 1px solid var(--grey-light);
  }

  @media (min-width: ${breakpoints.lg}px) {
    flex-basis: calc(35% - (24px + 1px));
    padding: 0 24px 0 0;
  }
`;

const Featured = ({
  spotlightArticle, sidebarArticles, wideArticle, sideBar,
}) => (
  <OuterContainer>
    <FeaturedItems>
      <InnerContainer>
        <SpotlightArticle>
          {spotlightArticle}
        </SpotlightArticle>
        <SideBarArticles>
          <FlexSmallCardContainer>
            {sidebarArticles}
          </FlexSmallCardContainer>
        </SideBarArticles>
        <LastArticle>
          {wideArticle}
        </LastArticle>
      </InnerContainer>
    </FeaturedItems>
    <SideBar>
      {sideBar}
    </SideBar>
  </OuterContainer>
);

Featured.propTypes = {
  spotlightArticle: PropTypes.node,
  sidebarArticles: PropTypes.node,
  wideArticle: PropTypes.node,
  sideBar: PropTypes.node,
};

Featured.defaultProps = {
  spotlightArticle: undefined,
  sidebarArticles: undefined,
  wideArticle: undefined,
  sideBar: undefined,
};

export default Featured;
