import { ResponsiveImageConfiguration } from './graphql/api/graphql';

const getSourceSet = (
  imageConfigurations: ResponsiveImageConfiguration[],
  imageUrls: (string | null | undefined)[],
) => (
  imageUrls.slice(1).map(
    (imageUrl, index) => (`${imageUrl} ${
      imageConfigurations[index + 1].width}w`),
  ).join(', ')
);

export default getSourceSet;
