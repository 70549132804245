import React from 'react';
import styled from '@emotion/styled';
import Skeleton from './Skeleton';
import breakpoints from '../Theme/breakpoints';

const DateSkeleton = () => (
  <Skeleton variant="rectangular" width="100px">
    &nbsp;
  </Skeleton>
);

const StyledSkeleton = styled(Skeleton)`
  height: 120px;

  @media (min-width: ${breakpoints.sm}px) {
    height: auto;
  }
`;

const TitleSkeleton = () => (
  <StyledSkeleton variant="rectangular" width="100%">
  &nbsp;
    <br />
  &nbsp;
  </StyledSkeleton>
);

export { DateSkeleton, TitleSkeleton };
