import React from 'react';
import { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  CardTextContainer,
  CardDate,
  FullWidthImageContainer,
  CardContainer,
  CardOverline,
  CardBodyText,
} from '../Card';
import Labels, { MemberAccessLabel } from '../Card/Labels';
import Overline from '../Typography/Overline';
import Headline4 from '../Typography/Heading/Headline4';
import breakpoints from '../Theme/breakpoints';
import cardPropertyTypes from './propertyTypes';
import Skeleton from '../SkeletonLoader/Skeleton';
import { DateSkeleton, TitleSkeleton } from '../SkeletonLoader/Card';

const FlexWideCard = styled.div`
display: flex;
flex-direction: column;

@media (min-width: ${breakpoints.sm}px) {
  flex-direction: row;
}
`;

const FlexWideCardItemImage = styled.div`
order: 1;

@media (min-width: ${breakpoints.sm}px) {
  order: 2;
  flex-basis: calc(50%);
}
`;

const FlexWideCardItemText = styled.div`
  order: 2;

@media (min-width: ${breakpoints.sm}px) {
  order: 1;
  flex-basis: calc(50%);
}
`;

const MiddleHeading = styled(Headline4)`
  && {
    margin: 0 0 16px;
  }
`;

const StyledCardTextContainer = styled(CardTextContainer)`

  @media (min-width: ${breakpoints.sm}px) {
    padding: 0 16px 0 0;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 0 24px 0 0;
  }
`;

type WideCardProperties = InferProps<typeof cardPropertyTypes> & {
  withPageMargins?: boolean;
};

const WideCard = ({
  title,
  description,
  category,
  insertedAt,
  image,
  labels = [],
  memberAccess = false,
  memberAccessLabelText,
  withPageMargins = false,
  showLoader = false,
} : WideCardProperties) => (
  <FlexWideCard>
    <FlexWideCardItemImage>
      <FullWidthImageContainer withPageMargins={withPageMargins}>
        {showLoader ? (
          <Skeleton variant="rectangular" width="100%" height="100vh" />
        ) : image}
      </FullWidthImageContainer>
    </FlexWideCardItemImage>
    <FlexWideCardItemText>
      <CardContainer className="card-container">
        <StyledCardTextContainer>
          {showLoader ? (
            <Skeleton
              variant="rectangular"
              width="100px"
            >
              <CardOverline>&nbsp;</CardOverline>
            </Skeleton>
          ) : (
            <CardOverline>
              <Labels category={category} labels={labels} />
              {memberAccess && (
                <Overline>
                  <MemberAccessLabel>
                    {memberAccessLabelText}
                  </MemberAccessLabel>
                </Overline>
              )}
            </CardOverline>
          )}
          <MiddleHeading tag="h2">
            {showLoader ? <TitleSkeleton /> : title}
          </MiddleHeading>
          {showLoader ? (
            <Skeleton variant="rectangular" width="100%">
              <CardBodyText>{description}</CardBodyText>
            </Skeleton>
          ) : (
            <CardBodyText>
              {description}
            </CardBodyText>
          )}
          {insertedAt && showLoader ? (
            <CardDate>
              <DateSkeleton />
            </CardDate>
          ) : (
            <CardDate>
              {insertedAt}
            </CardDate>
          )}
        </StyledCardTextContainer>
      </CardContainer>
    </FlexWideCardItemText>
  </FlexWideCard>
);

WideCard.propTypes = cardPropertyTypes;

export default WideCard;
