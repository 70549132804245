import React from 'react';
import { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  CardDate,
  CardTextContainer,
  FullWidthImageContainer,
  CardContainer,
  CardBodyText,
} from '../Card';
import Headline2 from '../Typography/Heading/Headline2';
import Overline from '../Typography/Overline';
import Label from '../Label';
import Labels, { MemberAccessLabel } from '../Card/Labels';
import Skeleton from '../SkeletonLoader/Skeleton';
import breakpoints from '../Theme/breakpoints';
import cardPropertyTypes from './propertyTypes';
import { TitleSkeleton }
  from '../SkeletonLoader/Card';
import DescriptionSkeleton from '../SkeletonLoader/Default';

const StyledCardBodyText = styled(CardBodyText)`
  && {
    margin: 12px 0 4px;

    @media (min-width: ${breakpoints.lg}px) {
      font-size: var(--size-body1);
      line-height: 1.8rem;
      color: var(--black-high);
    }
  }
`;

const StyledCardTextContainer = styled(CardTextContainer)`
  @media (min-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`;

const BigCardExtraLabels = styled.div`
  margin: 4px 0 0 0;
  span {
    margin: 0;
  }
`;

type BigCardProperties = InferProps<typeof cardPropertyTypes> & {
  withPageMargins?: boolean;
};

const BigCard = ({
  title,
  description,
  category,
  insertedAt,
  image,
  labels = [],
  memberAccess = false,
  withPageMargins = false,
  showLoader = false,
  memberAccessLabelText,
} : BigCardProperties) => (
  <CardContainer className="card-container">
    <FullWidthImageContainer withPageMargins={withPageMargins}>
      {showLoader
        ? <Skeleton variant="rectangular" width="100%" height="100vh" />
        : (
          <>
            {image}
            <Label
              className="highlight"
            >
              <Labels category={category} labels={labels} />
            </Label>
          </>
        )}
    </FullWidthImageContainer>
    <StyledCardTextContainer>
      <Headline2 tag="h2">
        {showLoader ? <TitleSkeleton /> : title}
      </Headline2>
      <StyledCardBodyText>
        {showLoader ? (
          <DescriptionSkeleton />
        ) : description}
      </StyledCardBodyText>
      {showLoader ? (
        <CardDate>
          <Skeleton
            variant="rectangular"
            width="100px"
            style={{ margin: 'auto' }}
          />
        </CardDate>
      ) : (insertedAt && (
      <CardDate>
        {insertedAt}
      </CardDate>
      ))}
      { memberAccess && (
        <BigCardExtraLabels>
          <Overline>
            <MemberAccessLabel>
              {memberAccessLabelText}
            </MemberAccessLabel>
          </Overline>
        </BigCardExtraLabels>
      )}
    </StyledCardTextContainer>
  </CardContainer>
);

BigCard.propTypes = cardPropertyTypes;

export default BigCard;
