import React from 'react';
import Skeleton from './Skeleton';

const DescriptionSkeleton = () => (
  <Skeleton variant="rectangular" width="100%">
    &nbsp;
    <br />
    &nbsp;
    <br />
    &nbsp;
  </Skeleton>
);

export default DescriptionSkeleton;
